import {
  TideItemForDestinations,
  TideItemForHomepage,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForSearchPage,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Icon from "../components/icon";
import Layout from "../components/layout";
import Link from "../components/link";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import PageComponents from "../components/page-components";
import React from "react";
import StickyBar from "../components/sticky-bar";
import { compact, first, isEmpty } from "lodash";
import { graphql } from "gatsby";
import { getChildNavigationLinks } from "../utils";
import QSM from "../app/search-results/qsm";
import { QSMStoreProvider } from "../app/search-results/qsm-store/context";
import { initialQSMState, qsmReducer } from "../app/search-results/qsm-store/reducer";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface HomepageTemplateProps {
  data: HomepageData;
  pageContext: {
    id: string;
    itemId: number;
    language: string;
  };
}

const HomepageTemplate: React.FC<HomepageTemplateProps> = ({ data, pageContext }) => {
  const generalPageContent = data.page?.content?.general;
  const title = compact([generalPageContent?.titlePrefix, generalPageContent?.title]).join(" ");
  const notificationBar = first(data.notifications?.nodes);
  const { t, language } = useI18next();

  return (
    <QSMStoreProvider initialState={initialQSMState} reducer={qsmReducer}>
      <Layout>
        <Seo
          title={title}
          language={language}
          seoTitle={data.page.content?.seo?.seoTitle ?? undefined}
          seoDescription={data.page.content?.seo?.seoDescription ?? undefined}
          seoKeywords={data.page.content?.seo?.seoKeywords ?? undefined}
        />
        {!isEmpty(notificationBar?.content?.general?.notification) && (
          <NotificationBar
            text={notificationBar?.content?.general?.notification ?? ""}
            icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
            backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
          />
        )}
        <Navbar
          mainNavigationSection={data.mainNavigationSection}
          fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
          fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
          socialNavigationSection={data.socialNavigationSection}
          legalNavigationSection={data.legalNavigationSection}
          phone={data.website?.content?.contact?.phone ?? ""}
          destinations={data.navigationDestinations}
        />
        <StickyBar
          navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
          actionsComponent={
            data.searchPage && (
              <Link
                href={`/${data.searchPage.content?.general?.slug}`}
                internal
                title={t("SEARCH_AND_BOOK")}
                className="cta cta--icon"
              >
                <Icon name="ui-search" />
                <Trans>SEARCH_AND_BOOK</Trans>
              </Link>
            )
          }
        />
        <Hero
          type="qsm"
          title={generalPageContent?.title ?? undefined}
          titlePrefix={generalPageContent?.titlePrefix ?? undefined}
          imageSrc={generalPageContent?.headerImage?.url ?? undefined}
        />
        <section className="section section--home-qsm">
          <QSM searchPagePath={data.searchPage.content?.general?.slug ?? undefined} />
        </section>
        <PageComponents componentItems={compact(data.page.childItems)} />
        <Footer
          footerNavigationSection={data.footerNavigationSection}
          socialNavigationSection={data.socialNavigationSection}
          legalNavigationSection={data.legalNavigationSection}
          address={data.website?.content?.contact?.address ?? ""}
          phone={data.website?.content?.contact?.phone ?? ""}
          email={data.website?.content?.contact?.email ?? ""}
          uspGroup={data.footerUspGroup}
          newsletterForm={data.newsletterForm}
        />
      </Layout>
    </QSMStoreProvider>
  );
};

interface HomepageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  page: TideItemForHomepage;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query HomePageQuery($itemId: Int, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    page: tideItemForHomepage(itemId: { eq: $itemId }, language: { eq: $language }) {
      id
      content {
        general {
          title
          titlePrefix
          headerImage {
            url
          }
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      ...pageComponentsFragment
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default HomepageTemplate;
